import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import Head from 'components/head';
import { Box, Text, Anchor, ThemeContext } from 'grommet';
import SectionTitle from 'components/title/sectionTitle';
import Img from 'gatsby-image';

const customTheme = {
  global: {
    focus: {
      border: {
        color: 'white',
      },
    },
  },
};

const SupportManuals = ({ data }) => (
  <Layout>
    <Head pageTitle={data.supportJson.title} />
    <ThemeContext.Extend value={customTheme}>
      <Box fill="horizontal">
        <Box width="xlarge" alignSelf="center">
          <SectionTitle title="Support" />
          <Box
            direction="row-responsive"
            justify="center"
            gap="large"
            margin="small"
            alignSelf="center"
            align="center"
            pad="small"
          >
            <Box width="medium">
              <Box width="250px" alignSelf="center" pad={{ top: '20px' }}>
                <Img
                  fluid={
                    data.supportJson.pagePic2
                      ? data.supportJson.pagePic2.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OtoSim 2™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="Installation and User Guide"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/1Ai2y9gPGcxelMqpUT4c-RaKPMXCX7DWK/view"
                  alignSelf="center"
                  margin={{ bottom: 'small' }}
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <Anchor
                  label="Troubleshooting OtoSim 2™"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/18gBKR-__8NKqqIXR0oxDGYQj9Ksck-9T/view?usp=sharing"
                  alignSelf="center"
                  margin={{ bottom: 'small' }}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
            <Box width="medium">
              <Box width="250px" alignSelf="center" pad={{ top: '20px' }}>
                <Img
                  fluid={
                    data.supportJson.pagePic3
                      ? data.supportJson.pagePic3.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OphthoSim™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="Installation and User Guide"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/1oMQyKSalTUXRnSFIXJfEk09krGnH3oki/view"
                  alignSelf="center"
                  margin={{ bottom: 'small' }}
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <Anchor
                  label="Troubleshooting OphthoSim™"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/18gBKR-__8NKqqIXR0oxDGYQj9Ksck-9T/view?usp=sharing"
                  alignSelf="center"
                  margin={{ bottom: 'small' }}
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
            <Box width="medium">
              <Box width="160px" alignSelf="center">
                <Img
                  fluid={
                    data.supportJson.pagePic1
                      ? data.supportJson.pagePic1.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OtoSim Mobile™
              </Text>
              <Anchor
                label="Installation and User Guide"
                color="neutral-3"
                href="https://drive.google.com/open?id=1k1ST4lwWV3gixIeEtFPzhmc4xI7vSvFK"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
              <Anchor
                label="User Guide for PC"
                color="neutral-3"
                href="https://drive.google.com/file/d/1N7CTe6HymbC2xxBN0q1JD1GZjdM7VQ3D/view?usp=sharing"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
              <Anchor
                label="Quick Start for Smartphones and Tablets"
                color="neutral-3"
                href="https://drive.google.com/file/d/16HoTkgDXXxiOuhBYI9Ya1AiQZ4YTj8zv/view"
                alignSelf="center"
                rel="noopener noreferrer"
                target="_blank"
              />
            </Box>
            <Box width="medium">
              <Box width="160px" alignSelf="center" pad={{ top: '70px' }}>
                <Img
                  fluid={
                    data.supportJson.pagePic4
                      ? data.supportJson.pagePic4.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                textAlign="center"
              >
                Mobile Educators Portals™
              </Text>
              <Box alignSelf="center">
                <Anchor
                  label="OtoSim Mobile Educators Portal User Guide for PC"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/1-fJuQ7vGRj5QWiNsIT5j4KoKZppfG_74/view"
                  alignSelf="center"
                  margin={{ bottom: 'small' }}
                  rel="noopener noreferrer"
                  target="_blank"
                />
                <Anchor
                  label="OphthoSim Mobile Educators Portal User Guide for PC"
                  color="neutral-3"
                  href="https://drive.google.com/file/d/1jJcQ9LMY2hcTe-IEXQEaIzHsEz7zW6-5/view"
                  alignSelf="center"
                  rel="noopener noreferrer"
                  target="_blank"
                />
              </Box>
            </Box>
            <Box width="medium">
              <Box width="130px" alignSelf="center" pad={{ top: '40px' }}>
                <Img
                  fluid={
                    data.supportJson.pagePic5
                      ? data.supportJson.pagePic5.childImageSharp.fluid
                      : {}
                  }
                  alt="Support Picture"
                />
              </Box>
              <Text
                size="large"
                margin={{ top: 'small', bottom: 'small' }}
                alignSelf="center"
              >
                OphthoSim Mobile™
              </Text>
              <Anchor
                label="Installation and User Guide"
                color="neutral-3"
                href="https://drive.google.com/file/d/1kIfAqiWuZYdEhYXAQys3RlqAhOjSJitl/view?usp=sharing"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
              <Anchor
                label="User Guide for PC"
                color="neutral-3"
                href="https://drive.google.com/file/d/1ZOWg-HTxXeBz1R7YUq9cEdV4Zv-fb7Ae/view"
                alignSelf="center"
                margin={{ bottom: 'small' }}
                rel="noopener noreferrer"
                target="_blank"
              />
              <Anchor
                label="Quick Start for Smartphones and Tablets"
                color="neutral-3"
                href="https://drive.google.com/file/d/16HoTkgDXXxiOuhBYI9Ya1AiQZ4YTj8zv/view"
                alignSelf="center"
                rel="noopener noreferrer"
                target="_blank"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </ThemeContext.Extend>
  </Layout>
);

SupportManuals.propTypes = {
  data: PropTypes.object.isRequired,
};

export default SupportManuals;

export const query = graphql`
  query SupportManualsQuery {
    supportJson {
      title
      pagePic1 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic2 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic3 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic4 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      pagePic5 {
        childImageSharp {
          fluid(maxWidth: 500, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
